@import "variables.scss";
@import "default.scss";
@import "header.scss";
@import "menu.scss";
@import "instructions.scss";
@import "frontpage.scss";
@import "textPage.scss";

#root {
    width: 100%;
    height: 100%;
}
.renderCanvas {
    width: 100%;
    height: 100%;
    touch-action: none;
    z-index: 10;
}

.main {
    position: absolute;
    top: 0;
    left: 0;
    color: $pc;
    width: 100vw;
    height: 100vh;
}


.splashScreen {
    width: 100%;
    height: 100%;
    background: black;
    position: absolute;
    left: 0;
    top: 0;
    z-index: 50;
    width: 100%;
    height: 100%;
    //background: red;
    padding: 3em;
}

.splashScreen-loading-container {
    position: relative;
    width: 100%;
    height: calc(100% - 4em);
}

.splashScreen-svg {
    fill: white;
    object-fit: fill;
    width: 100%;
    height: 100%;
}

.splashScreen-loading {
    position: relative;
    z-index: 100;
    width: 18vw;
    max-width: 25vh;
    height: 18vw;
    fill: white;
    animation:spin 4s linear infinite;
}

@keyframes spin { 
    100% { 
    transform: rotate(360deg); 
     
    } 
}
.main-container {
    width: 100%;
    height: 100%;  
}
canvas {
    width: 100%;
    height: 100%;
}

.splashScreen-loading-pos {
    z-index: 100;
    width: 8vw;
    height: 8vw;
    position: absolute;
    top: calc(50% - 5vw);
    left: 0;
    transform: translate(0em,-50%);
}


.splashScreen-contributors {
    z-index: 100;
    position: relative;
    color: white;
    font-size: $big;
    width: 100%;
    text-align: center;
    margin-top: $lh;
}




.artwork-container {
    position: fixed;
    width: 100%;
    height: 100%;
    background: black;
    backdrop-filter: blur(20px);
    top: 0;
    left: 0;
    z-index: 10;
    color: white;
    padding: 10%;
    padding-top: $lh * 3;
    padding-bottom: $lh;
    overflow: scroll;

    @media only screen and (max-width: $firsbrekpoint) {
        padding: 5%;
        padding-top: $lh * 6;
    }
}

.artwork-videoplayer {
    width: 100%;
    height: 100%;
    
    iframe {
        background: black;
        width: 100%;
        height: 100%;
    }
}

.artwork-container-close {
    padding-bottom: $lh / 4;
    font-family: 'youth';
    letter-spacing: .05em; 
    font-size: $big; 
}



.video-container {
    width: 100%;
    position: relative;
    background: white;
    border-radius: $radius;

    .video-container-content {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: $radius;
        overflow: hidden;
    }
}

.video-container-after {
    content: '';
    width: 100%;
    display: block;
    height: 0;
    width: 100%;
}



.splashScreen-animation-enter {
    opacity: 0;
}
.splashScreen-animation-enter-active {
    opacity: 1;
    transition: opacity 800ms;
}
.splashScreen-animation-exit {
    opacity: 1;
}
.splashScreen-animation-exit-active {
    opacity: 0;
    transition: opacity 800ms;
}

.mt-1 {
    margin-top: $lh;
}

.artwork-caption {
    margin-top: $lh / 2;
    opacity: 1;
}