.header {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    font-family: youth;
    font-size: $big;
    line-height: 1.488rem;
    color: $pc;
    padding: $lh / 4;
    padding-top: $lh / 8;
    letter-spacing: .05em;
    z-index: 90;
    width: calc(100vw - 5em);
    user-select: none;
    color: $pc;

    a {
        color: white;
        text-decoration: none;
    } 
}

.header-title {
    position: relative;
    z-index: 500;
    color: $pc;  
}

.header-logo {
    letter-spacing: .5em;
    color: $pc; 
}

.menu-open {
    display: inline-block;
    position: absolute;
    top: 0;
    right: $lh / 4;
    z-index: 900;
    font-family: youth;
    font-size: $big;
    color: $pc;
    user-select: none;
    cursor: pointer; 
}

.footer-right {
    position: fixed;
    bottom: 0;
    left: 0;
    color: white;
    font-family: youth;
    font-size: $big;
    padding: $lh / 4;
    padding-top: 0;
    background: white;
    color: black;
    margin: $lh / 4;
    border-radius: $radius / 8;
    opacity: 0.5;
    letter-spacing: .05em;
    cursor: pointer;
    user-select: none;
    z-index: 200;
}

.footer-left {
    position: fixed;
    bottom: 0.5em;
    right: 0;
    color: white;
    font-family: youth;
    margin: $lh / 4;
    font-size: $big;
    padding: $lh / 4;
    letter-spacing: .05em;
    user-select: none;
    font-size: 1em;
    opacity: 0.5
}

.header-logo-subtitle {
    @media only screen and (max-width: $firsbrekpoint) {
        display: none;
    }
}