
$lh: 1.42em;

.geochat {
    //font-size: 1.3vw;
    position: absolute;
    width: 20%;
    min-width: 20em;
    height: auto;
    bottom: 0;
    right: 0;
    z-index: 300;
    pointer-events: none;
    
    @mixin media {
        transform: translate( -200%, 0); 
    } 

    @media only screen and (min-width: 1400px) {
        font-size: 1.3vw;
  } 
}

.gch-root {
    position: fixed;
    width: 20%;
    height: auto;
    bottom: 0;
    left: 0;
    z-index: 300;
    pointer-events: none;
    display: none;
    pointer-events: none;

}

.geochat {
    position: absolute;
    width: 20%;
    height: auto;
    bottom: $lh * 4;
    right: 0;
    z-index: 300;
    pointer-events: none;
    padding: $lh / 2;
    text-align: right;
}

.message-text {
    opacity: 1;
    margin: $lh / 2;
    background: white;
    display: table;
    width: auto;
    box-shadow: inset 0 0 10px #00000052;
    border-radius: 0.5em;
    text-align: center;
    vertical-align: middle;
    color: grey;
    font-size: 0.85em;
    //text-transform: uppercase;
    padding: $lh / 2;
    font-size: 1.2em;
    pointer-events: none;
    span {
        padding: $lh / 2;
        display: inline-block;
    }
}

.message-image {
    width: 8em;
    height: 8em;
    margin: $lh / 2;
    border-radius: 1000em;
    overflow: hidden;
    box-shadow: 0px 0px 22px 0px rgba(0, 0, 0, 0.2);
    pointer-events: none;

    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
}


.example-enter {
    opacity: 0.01;
}

.example-enter.example-enter-active {
    opacity: 1;
    transition: opacity 500ms ease-in;
}

.example-exit {
    opacity: 1;
}

.example-exit.example-exit-active {
    opacity: 0.01;
    transition: opacity 500ms ease-in;
}

.typing-indicator {
    background-color: #E6E7ED;
    width: auto;
    height: $lh 8 2;
    border-radius: 0.5em;
    padding: $lh / 1.5;
    font-size: 0.85em;
    display: table;
    margin: $lh / 2;
    margin-left: $lh / 2;
    position: relative;
    animation: 2s bulge infinite ease-out;
}

.typing-indicator:before,
.typing-indicator:after {
    content: '';
    position: absolute;
    bottom: -2px;
    left: -2px;
    height: $lh / 2;
    width: $lh / 2;
    border-radius: 50%;
    background-color: #E6E7ED;
}

.typing-indicator:after {
    height: $lh / 3;
    width: $lh / 3;
    left: -10px;
    bottom: -10px;
}

.typing-indicator span {
    height: 10px;
    width: 10px;
    float: left;
    margin: 0 1px;
    background-color: #9E9EA1;
    display: block;
    border-radius: 50%;
    opacity: 0.4;
}

.typing-indicator span:nth-of-type(1) {
    animation: 1s blink infinite 0.3333s;
}

.typing-indicator span:nth-of-type(2) {
    animation: 1s blink infinite 0.6666s;
}

.typing-indicator span:nth-of-type(3) {
    animation: 1s blink infinite 0.9999s;
}

@-webkit-keyframes blink {
    50% {
        opacity: 1;
    }
}

@keyframes blink {
    50% {
        opacity: 1;
    }
}

@-webkit-keyframes bulge {
    50% {
        transform: scale(1.05);
    }
}

@keyframes bulge {
    50% {
        transform: scale(1.05);
    }
}