.language {
    position: fixed;
    top: 0.05em;
    right: 6em;
    font-family: youth;
    color: white;
    text-decoration: none;
    z-index: 100;
    font-size: 1em;

    a {
        color: white;
        text-decoration: none;
        font-size: 1.5em;
        z-index: 10;

        @media only screen and (max-width: $firsbrekpoint) {
            font-size: 1em !important;
        }
    }

    a:hover {
        opacity: 0.5;
        transition: all 0.5s;
    }

    @media only screen and (max-width: $firsbrekpoint) {
        top: 0.1em;
    }

}

.fp-container {
    padding-left: $lh * 4;
    padding-right: $lh * 4;
    width: 100%;
    height: 100%;
    overflow: scroll;
    padding-bottom: $lh * 5;

    


    @media only screen and (max-width: 1050px) {
        padding-left: $lh / 4;
        padding-right: $lh / 4;
    }

    @media only screen and (max-width: $firsbrekpoint) {
        padding-left: $lh / 4;
        padding-right: $lh / 4;
        font-size: 1.5em;
    }
}

.fp-introduction {
    padding-left: 7em;
    margin-top: $lh * 2;

    @media only screen and (max-width: $firsbrekpoint) {
        padding-left: 0;
        margin-bottom: $lh *2;
    }
}

.fp-col-container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: $lh;

    @media only screen and (max-width: $firsbrekpoint) {
        // margin-top: 2.5em  
        grid-template-columns: 1fr;
    }
}

.fp-title {
    font-family: pyramidstitle;
    font-size: 5vw;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 0.84;
    letter-spacing: normal;
    text-align: center;
    color: #ffffff;
    text-transform: uppercase;

    @media only screen and (max-width: $firsbrekpoint) {
        font-size: 2em;
    }
}


.fp-col-width {
    color: white;
    text-decoration: none;
    grid-column: span 1;
    margin-top: $lh * 2;
    @media only screen and (max-width: $firsbrekpoint) {
        margin-bottom: $lh * 4;
    }
}

.fp-col-width:hover {
    opacity: 0.5;
    transition: 0.3s all;
}


.fp-subtitle {
    font-family: life;
    line-height: 1.1;
    letter-spacing: normal;
    text-align: center;
    color: #ffffff;
    font-style: italic;
    margin-bottom: $lh * 2;

}

.fp-main {
    margin-bottom: $lh * 2;
    padding-left: $lh * 4;
    padding-right: $lh * 4;

    @media only screen and (max-width: 1300px) {
        padding-left: $lh / 2;
        padding-right: $lh / 2;
    }

    @media only screen and (max-width: $firsbrekpoint) {
        padding-left: $lh / 2;
        padding-right: $lh / 2;
    }
}


.fp-read {
    font-size: 2em;
    font-family: youth;
    height: $lh * 2;
    width: 100;
    position: relative;
    display: block;
    // background: red;
}

.fp-read-text {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-60%);
    color: black;
    width: 100%;
    text-align: center;
}

.fp-read-bck {
    position: absolute;
    top: 0;
    left: 0;
    -webkit-filter: blur(17px);
    filter: blur(17px);
    width: 100%;
    height: 100%;
    border-radius: 100em;
    padding-left: 2em;
    padding-right: 2em;
    svg {
        width: 100%;
        height: 100%;
        // background: red;
    }

    @media only screen and (max-width: $firsbrekpoint) {
        padding: 0;
        padding-left: 0;
        padding-right: 0;
    }
}