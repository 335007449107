.tp-container {
    position: relative;
    overflow: scroll;
    width: 100%;
    height: 100%;

    h1 {
        font-family: pyramidstitle;
        font-size: 10vw;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 0.84;
        letter-spacing: normal;
        text-align: center;
        color: #ffffff;
        z-index: 2;
        position: relative;
        padding-left: 1em;
        padding-right: 1em;
        margin-top: 0.45em;
        text-transform: uppercase;

        .cls-1{
            fill:#4a3996
        }

        @media only screen and (max-width: 1150px) {
            margin-top: 1.5em  
        }

        @media only screen and (max-width: $firsbrekpoint) {
            // margin-top: 2.5em  
            margin-top: 100vw; 
        }

    }

    @media only screen and (max-width: $firsbrekpoint) {
        font-size: 1.5em;
    }
}

.tp-title-small {
    font-size: 7vw !important;

    @media only screen and (max-width: $firsbrekpoint) {
        font-size: 1.5em !important;
    }
}

.tp-header-img {
    .cls-1 {
        fill:#4a3996
    }

    width: 100%;
    height: 23vw;
    position: absolute;
    top: 0;
    left: 0;

    @media only screen and (max-width: $firsbrekpoint) {
        display: none;  
    }    
}

.tp-header-img-small {
    .st0 {
        fill:#4a3996
    }

    width: 100%;
    height: 100vw;
    position: absolute;
    top: 0;
    left: 0;
    display: none;

    @media only screen and (max-width: $firsbrekpoint) {
        display: block;
    }    
}

.tp-subtile {
    font-family: life;
    line-height: 1.1;
    letter-spacing: normal;
    text-align: center;
    color: #ffffff;
    font-style: italic;
    margin-bottom: $lh * 2;
    font-size: 1.5em;

    @media only screen and (max-width: $firsbrekpoint) {
        font-size: 1em
    }
}

.tp-main {
    margin-top: $lh;
    padding-left: 15%;
    padding-right: 15%;
    line-height: 1.42;
    font-size: 1.2em;

    a {
        margin-bottom: $lh;
    }

    @media only screen and (max-width: 1000px) {
        padding-left: 7.5%;
        padding-right: 7.5%; 
    }

    @media only screen and (min-width: 1500px) {
        font-size: 1.5em;
    }

    @media only screen and (max-width: $firsbrekpoint) {
        padding: $lh / 2; 
    }
}

.tp-footer {
    margin: auto;
    margin-top: $lh * 4;
    width: 33%;
    text-align: center;
    margin-bottom: $lh * 10;
    display: block;
    text-decoration: none;
    color: white;

    @media only screen and (max-width: 1000px) {
        width: 50%;
        padding: 1em; 
    }

    @media only screen and (max-width: $firsbrekpoint) {
        width: 100%;
        padding: 1em; 
    }
}

.tp-footer:hover {
    opacity: 0.5;
    transition: 0.3s all;
}    

.tp-footer-more {
    font-family: youth;
    font-size: 1.5em;
    margin-bottom: $lh / 2;
    position: relative;
}

.tp-footer-title {
    font-family: pyramidstitle;
    font-size: 3em;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 0.84;
    margin-bottom: $lh / 2;
    text-transform: uppercase;

    @media only screen and (max-width: $firsbrekpoint) {
        font-size: 1.5em;
    }
    
}

.tp-footer-subtitle {

    font-style: italic;
    margin-bottom: $lh * 2;
    font-size: 1.2em;
}

.fp-read-bck {
    position: absolute;
    top: 0;
    left: 0;
    -webkit-filter: blur(17px);
    filter: blur(17px);
    width: 100%;
    height: 100%;
    border-radius: 100em;
    padding-left: 2em;
    padding-right: 2em;
    
    svg {
        width: 100%;
        height: 100%;
        // background: red;
    }

    @media only screen and (max-width: $firsbrekpoint) {
        padding: 0;
        padding-left: 0;
        padding-right: 0;
    }
}