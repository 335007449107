.instructions-container {
    position: fixed;
    top: 75%;
    left: 50%;
    z-index: 10;
    padding-bottom: $lh / 4;
    font-family: 'youth';
    letter-spacing: .05em; 
    font-size: $big; 
    color: white;
    padding: $lh / 2;
    transform: translate(-50%, -50%);
    border-radius: $radius / 2;
    // background: white;
    // color: black;
    // backdrop-filter: brightness(100%) blur(10px);
    pointer-events: none;
    text-align: center;
    width: 100%;

    span {
        background: white;
        color: black;
        font-size: 0.9em;
        border-radius: 0.1em;
        line-height: 0.9;
        display: inline-block;
        padding: 0.2em;
        padding-bottom: 0.2em;
        padding-top: 0;
        margin-left: 0.15em;
        width: 1.2em;
        text-align: center;
    }
}